var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ct-table",
        {
          attrs: {
            loaded: _vm.loaded,
            items: _vm.activeItems(),
            fields: _vm.fields,
            "sticky-header": false,
            responsive: false,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "cell(active_renewal)",
                fn: function (data) {
                  return _vm.canRenew
                    ? [
                        _vm.canUpdateActiveRenewal(data.item)
                          ? _c(
                              "b-form-checkbox",
                              {
                                staticClass: "toggle",
                                attrs: {
                                  name: "check-button",
                                  variant: "primary",
                                  switch: "",
                                  size: "md",
                                  disabled: data.item.status !== "completed",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateRenewalService(data.item)
                                  },
                                },
                                model: {
                                  value: data.item.active_renewal,
                                  callback: function ($$v) {
                                    _vm.$set(data.item, "active_renewal", $$v)
                                  },
                                  expression: "data.item.active_renewal",
                                },
                              },
                              [_vm._v("\n        Auto Renewal\n      ")]
                            )
                          : _vm._e(),
                      ]
                    : undefined
                },
              },
              {
                key: "cell(should_notify)",
                fn: function (data) {
                  return [
                    _vm.canSetRemindMe(data.item)
                      ? _c(
                          "b-form-checkbox",
                          {
                            staticClass: "toggle",
                            attrs: {
                              name: "check-button",
                              "button-variant": "primary",
                              switch: "",
                              size: "md",
                              disabled:
                                data.item.active_renewal ||
                                data.item.status === "removed",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.updateRemindMe(data.item)
                              },
                            },
                            model: {
                              value: data.item.should_notify,
                              callback: function ($$v) {
                                _vm.$set(data.item, "should_notify", $$v)
                              },
                              expression: "data.item.should_notify",
                            },
                          },
                          [_vm._v("\n        Renewal Reminder\n      ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _vm.canAddService(data.item)
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.add-vehicle-service-modal",
                                modifiers: {
                                  "add-vehicle-service-modal": true,
                                },
                              },
                            ],
                            staticClass: "action-button",
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.addService(data.item)
                              },
                            },
                          },
                          [_vm._v("\n        Additional Services\n      ")]
                        )
                      : _vm._e(),
                    _vm.statusIsNew(data.item)
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName:
                                  "v-b-modal.edit-vehicle-registration-modal",
                                modifiers: {
                                  "edit-vehicle-registration-modal": true,
                                },
                              },
                            ],
                            staticClass: "action-button",
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.editVehicle(data.item)
                              },
                            },
                          },
                          [_vm._v("\n        Edit\n      ")]
                        )
                      : _vm._e(),
                    _vm.statusIsNew(data.item)
                      ? _c(
                          "b-button",
                          {
                            staticClass: "action-button action-remove",
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.removeVehicle(data.item)
                              },
                            },
                          },
                          [_vm._v("\n        Remove\n      ")]
                        )
                      : _vm._e(),
                    _vm.noLongerHaveVehicle(data.item)
                      ? _c(
                          "b-button",
                          {
                            staticClass: "action-button action-remove",
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.noLongerHaveVehicleRemove(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n        I no longer have this vehicle\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [_c("template", { slot: "cell(company_name)" })],
        2
      ),
      _vm.removedItems().length
        ? _c(
            "div",
            { staticClass: "mt-5 mb-5" },
            [
              _c(
                "b-card",
                { staticClass: "removed-vehicles" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.collapse-removed",
                          modifiers: { "collapse-removed": true },
                        },
                      ],
                      attrs: {
                        variant: "link",
                        "aria-label": "Removed Vehicles link",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showRemoved = !_vm.showRemoved
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("Removed Vehicles")]),
                      _vm.showRemoved
                        ? _c("b-icon-chevron-up")
                        : _c("b-icon-chevron-down"),
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    { attrs: { id: "collapse-removed" } },
                    [
                      _c("ct-table", {
                        attrs: {
                          loaded: _vm.loaded,
                          items: _vm.removedItems(),
                          fields: _vm.removedFields,
                          "sticky-header": false,
                          responsive: false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }